import React from "react";
import Register from "./Register";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Payment from "./Payment"

import PaymentSucess from "./PaymentSucess"



function App() {
  var url = new URL(window.location.href);
  let price = "1.50"
    if(url.searchParams.get('plan') != null)
    {
      console.log('got here')
      var plan = url.searchParams.get('plan')
      console.log('UC',plan)
      plan = plan.toUpperCase();
      if(plan === 'BASIC')
      {
        price = "1.50"
      }
      else if(plan === 'PROFESSIONAL')
      {
        price = "3.00"
      }
      else 
      {
        price = "1.50"
      }

    }

  return (
    <div>
     <Router>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
    {/* <Payment />  */}
   
{/* <Payment /> */}
    {/* <PaymentSucess /> */}
        

        <Switch>
        
  {/* <Route path="/" exact component={() => <Register price={price}/> } /> */}
          <Route path="/payment_secc" render={(props) => <PaymentSucess/>} />
           <Route path="/payment" render={(props) => <Payment />} />
           <Route path="/" render={(props) => <Register price={price}/>} />

           
        </Switch>
      
      </ThemeProvider>
     </Router>
    </div>
  );
}

export default App;
