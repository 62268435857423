import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import {
  Typography,
  TextField,
  withTheme,
  CardActionArea,
  useMediaQuery,
} from "@material-ui/core";
import Uc1 from "./images/Uc1.png";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Logo4 from "./images/Logo4.png";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import MuiPhoneNumber from "material-ui-phone-number";
import startsWith from "lodash.startswith";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

toast.configure();
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const useStyles = makeStyles((theme) => ({
  back: {
    //green
    // background: "linear-gradient(to right, #2AC4A3 40%, #16A5A9 60%)",
    //blue
    // background: "linear-gradient(to top, #00c6fb 0%, #005bea 100%)",
    //yellow
    // background: "linear-gradient(315deg, #fbb034 0%, #ffdd00 74%)",
    //Italian Stallion
    background:
      "linear-gradient(112.23859771255206deg, rgba(1, 96, 186,1) 61.429355783308935%,rgba(0, 0, 0,1) 100.00732064421669%)",
    //  background:"linear-gradient(to right, rgba(22,163,170,0.5), rgba(22,163,170,1))",
    height: "100vh",
    // height:"",
    // width:"100%",
  },
  card: {
    alignContent: "center",
    justify: "center",
    width: "90%",
    height: "93%",
    // overflowY: screenMD ? "auto" : "hidden",
    overflow: "hidden",
    borderRadius: "20px",
  },

  underLine: {
    "&hover": {
      backgroundColor: "#009700",
    },
  },
  label: {
    "&$focusedLabel": {
      color: "cyan",
      fontSize: "15em",
    },
    "&$erroredLabel": {
      color: "orange",
    },

    focusedLabel: {},
    erroredLabel: {},
  },
  addOnField: {
    width: "8rem",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0CA2A3",
      fontWeight: "bold",
      border: "1px solid",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0CA2A3",
      fontWeight: "bold",
      border: "2px solid",
    },
  },
  textField: {
    // marginTop: "1em",
    width: "15rem",
    // color: "blue",
    // "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "#0CA2A3"
    // },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0CA2A3",
      fontWeight: "bold",
      border: "1px solid",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0CA2A3",
      fontWeight: "bold",
      border: "2px solid",
    },
  },
  textFieldLabel: {
    "&.MuiInputLabel-root.Mui-focused": {
      color: "#0CA2A3",
      fontSize: "1rem",
      fontWeight: 600,
    },
  },

  // message: {
  //   width: "100%",
  //   // marginTop: "1em",
  //   marginRight: "2em",
  //   border: ` 2px solid #33c92d `,
  // },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  resetButton: {
    borderRadius: 6,
    padding: "0.6em",

    width: "9em",
    "&:hover": {
      backgroundColor: " #4EAB4D",
    },
    // textAlign: "center",
    // marginLeft: "4em",
    backgroundColor: " #080A3C",
    color: "white",
    // fontFamily: "'Roboto', sans-serif"
  },
  createButton: {
    borderRadius: 6,
    padding: "0.6em",

    width: "9em",
    "&:hover": {
      backgroundColor: "#080A3C",
    },
    // textAlign: "center",

    backgroundColor: "#4eab4d",
    color: "white",
    fontFamily: "'Roboto', sans-serif",
  },

  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
  },
  textFieldLabel: {
    "&.MuiInputLabel-root.Mui-focused": {
      color: "#0CA2A3",
      fontSize: "1rem",
      fontWeight: 600,
    },
  },
  background: {
    backgroundImage: `url(${Uc1})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // height: "90vh",
    height: "-webkit-fill-available",
    // height:0,
    // height:"100%",
    // weight:"100%",
    objectFit: "cover",
  },
  logo: {
    marginTop: "0.2em",
  },
  total: {
    marginTop: "1em",
  },
  addOnTitle: {
    fontSize: "2em",
    fontWeight: "bold",
    fontFamily: "Roboto",
    color: "#4F4A67",
    textAlign: "center",
  },
  addOnPrice: {
    fontWeight: 500,
    fontSize: "1rem",
    textAlign: "center",
  },
  addOnCard: {
    width: "20vh",
    borderRadius: "8px",
    backgroundColor: "#fff",

    border: "solid 1px #d9d9d9",
  },
  addOnButton: {
    width: "9em",
    "&:hover": {
      backgroundColor: "#080A3C",
    },

    color: "white",
    backgroundColor: "#4eab4d",
    textAlign: "center",
  },
  addOnWidth: {
    [theme.breakpoints.up("md")]: {
      // width: "30vw",
    },
  },

  // resize:{
  //   fontSize:50
  // },
}));
function Register(props) {
  if (localStorage.getItem("total_price")) {
    localStorage.removeItem("total_price");
  }

  const currencies = [
    {
      value: "1.50",
      label: "Basic",
      price: "1.50",
    },
    {
      value: "3.00",
      label: "Professional",
      price: "3.00",
    },
  ];

  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  // const [data, setData] = useState({
  //   name: "",
  //   mobile: "",
  //   country: "",
  //   institution_name: "",
  //   role: "",
  //   email: "",
  //   city: "",
  //   institution_website: "",
  //   students: "100",
  //   price: props.price,
  // });

  const [total, setTotal] = useState(0);
  const [vc_plan, setVcplan] = useState(0);
  const [isActive, setActive] = useState(false);
  const [price_list, setPriceList] = useState(currencies);
  const [nameHelper, setNameHelper] = useState("");
  const [nameFocus, setNameFocus] = useState(true);
  const [mobileHelper, setMobileHelper] = useState("");

  const [countryHelper, setCountryHelper] = useState("");

  const [institution_nameHelper, setinstitution_nameHelper] = useState("");

  const [roleHelper, setRoleHelper] = useState("");

  const [emailHelper, setEmailHelper] = useState("");

  const [cityHelper, setCityHelper] = useState("");

  const [institution_websiteHelper, setinstitution_websiteHelper] = useState(
    ""
  );

  const [sessionHelper, setSessionHelper] = useState("");

  const [planHelper, setPlanHelper] = useState("");
  const [studentsHelper, setStudentsHelper] = useState("");

  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const screenMD = useMediaQuery(theme.breakpoints.down("md"));
  const [errorMessage, setErrorMessage] = useState("");
  const [addOnvValue, setAddOnValue] = React.useState("");

  const addOnHandleChange = (event) => {
    setAddOnValue(event.target.value);
  };

  const stopExpansion = (event) =>{
    event.stopPropagation();
    if(document.getElementById('add-on-check').checked)
    sessionCheck();
    else
    setSessionHelper('');
  }

  const notify = () => {
    toast.error("Something went wrong", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
  };

  useEffect(() => {
    localStorage.clear();
    onAdd();
  }, [price_list]);


  async function handleSubmit(event) {
    setActive(true);
    event.preventDefault();    
    const data = {
      name: document.getElementById("name").value,
      mobile: document.getElementById("mobile").value,
      email: document.getElementById("email").value,
      country: document.getElementById("country").value,
      role: document.getElementById("role").value,
      institution_name: document.getElementById("institution_name").value,
      institution_website: document.getElementById("institution_website").value,
      city: document.getElementById("city").value,
      students: document.getElementById("students").value,
      price: document.getElementById("price").value,
    };
    let new_data = data;
    let chosen_plan = document.getElementById("plan").options[
      document.getElementById("plan").selectedIndex
    ].text;
    let vc_plan_price = 0.0;
    new_data = Object.assign({ addons: {} }, new_data);
    if(document.getElementById('add-on-check').checked)
    {
      if (isEmptyOrSpaces(document.getElementById('sessions').value)) {
        setSessionHelper('Please enter a number')
        setActive(false);
        return
      }

      if (parseInt(document.getElementById('sessions').value) < 2000) {
        setSessionHelper('Minimum 2000 sessions. Please contact us for a custom plan')
        setActive(false);
        return
      }
      if (parseInt(document.getElementById('sessions').value) > 10000) {
        setSessionHelper('Maximum 10000 sessions. Please contact us for a custom plan')
        setActive(false);
        return
      }
      vc_plan_price = vc_price()
      new_data.addons = {
            name: "Virtual Classroom",
            sessions: document.getElementById('sessions').value,
            price: vc_plan_price,
          };

    }

    // if (
    //   document.getElementById("vc_starter1").hasAttribute("vc_plan_selected")
    // ) {
    //   vc_plan_price = 999.0;
    //   new_data.addons = {
    //     name: "Virtual Classroom",
    //     plan: "Starter",
    //     price: "999.00",
    //   };
    // } else if (
    //   document.getElementById("vc_standard1").hasAttribute("vc_plan_selected")
    // ) {
    //   vc_plan_price = 1499.0;
    //   new_data.addons = {
    //     name: "Virtual Classroom",
    //     plan: "Standard",
    //     price: "1499.00",
    //   };
    // } else {
    // }
    let tot_price = parseFloat((parseFloat(document.getElementById("plan").options[document.getElementById("plan").selectedIndex].value) * parseInt(document.getElementById("students").value)) + parseFloat(vc_plan_price));
    tot_price = parseFloat(tot_price).toFixed(2);
    new_data = Object.assign({ total: tot_price, plan: chosen_plan }, new_data);
    if (data.country == "India") {
      let temp_price;
      let c_data = {
        sourcePrice: tot_price,
        sourceCurr: "USD",
        destCurr: "INR",
      };
      await axios
        .post(
          process.env.REACT_APP_API_URL + "lms/api/trial/convertCurrency",
          c_data
        )
        .then(async (response) => {
          console.log("LOL", response.data.message);
          temp_price = response.data.message;
          tot_price = Math.round((temp_price + Number.EPSILON) * 100) / 100;
          new_data.total = parseFloat(tot_price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
          setActive(false);
          return;
        });
    }
    console.log("sdfdsgf", new_data);
    try {
      if (data.mobile.length > 6) {
        const number_check = phoneUtil.parseAndKeepRawInput(
          data.mobile,
          countryCode
        );
        console.log(
          phoneUtil.isValidNumberForRegion(
            number_check,
            countryCode.toUpperCase()
          )
        );
        if (
          !phoneUtil.isValidNumberForRegion(
            number_check,
            countryCode.toUpperCase()
          )
        ) {
          setMobileHelper("Please enter a valid mobile no");
          setActive(false);
          return;
        }
      } else {
        setMobileHelper("Please enter a valid mobile no");
        setActive(false);
        return;
      }
    } catch (e) {
      setMobileHelper("Please enter a valid mobile no");
      setActive(false);
      return;
    }
    let valid_url = validURL(data.institution_website);
    if (!valid_url) {
      setinstitution_websiteHelper("Please enter a valid website");
      setActive(false);
      return;
    }
    let email = { email: data.email };
    await axios
      .post(process.env.REACT_APP_API_URL + "lms/api/trial/emailExists", email)
      .then((response) => {
        console.log(response);
        if (response.data.message) {
          setEmailHelper(
            "Email has already been registered. Please try again with another email"
          );
          setActive(false);
          return;
        } else {
          let mobile = { mobile: data.mobile };
          axios
            .post(
              process.env.REACT_APP_API_URL + "lms/api/trial/mobileExists",
              mobile
            )
            .then((response) => {
              console.log(response);
              if (response.data.message) {
                setMobileHelper(
                  "Mobile has already been registered. Please try again with another mobile"
                );
                setActive(false);
                return;
              } else {
                axios
                  .post(
                    process.env.REACT_APP_API_URL + "lms/api/trial/emailVerify",
                    email
                  )
                  .then((response) => {
                    console.log("Email", response.data);
                    if (response.data.message != "ok") {
                      setEmailHelper("Please enter a valid email address");
                      setActive(false);
                      return;
                    } else {
                      if (chosen_plan == "Free") {
                        new_data = Object.assign({ is_paid: "1" }, new_data);
                        axios
                          .post(
                            process.env.REACT_APP_API_URL + "lms/api/trial/",
                            new_data
                          )
                          .then((response) => {
                            console.log("Free Trial response", response);
                            localStorage.setItem("is_success", true);
                            history.push("/payment_secc");
                          })
                          .catch((error) => {
                            console.log("Mone", error);
                            setActive(false);
                            return;
                          });
                      } else {
                        new_data = Object.assign({ is_paid: "0" }, new_data);
                        axios
                          .post(
                            process.env.REACT_APP_API_URL + "lms/api/trial/",
                            new_data
                          )
                          .then((response) => {
                            console.log(response);
                            localStorage.setItem(
                              "invoice_id",
                              response.data.inv_id
                            );
                            localStorage.setItem("trial_id", response.data.id);
                            localStorage.setItem("total_price", new_data.total);
                            localStorage.setItem("country", new_data.country);
                            localStorage.setItem(
                              "data",
                              JSON.stringify(new_data)
                            );
                            history.push("/payment");
                          })
                          .catch((error) => {
                            console.log("Mone", error.response.data.message);
                            setActive(false);
                            return;
                          });
                        history.push("/payment");
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setActive(false);
                    return;
                  });
              }
            })
            .catch((error) => {
              console.log("Mone", error.response.data.message);
              setActive(false);
              return;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        notify();
        setActive(false);
        return;
      });

    // return;
    // data.country2 = country
    // setActive(false);
    return;
    // event.preventDefault();
    // setData({
    //   name: "",
    //   mobile: "",
    //   country: "",
    //   institution_name: "",
    //   role: "",
    //   email: "",
    //   city: "",
    //   institution_website: "",
    //   // plan: "basic",
    //   students: "",
    //   // price: "",
    //   students: "100",
    //   price: "0",
    // });
    // setTotal(0);
  }

  const sessionCheck = () => {
    if(document.getElementById('add-on-check').checked)
      {
        if (isEmptyOrSpaces(document.getElementById('sessions').value)) {
          setSessionHelper('Please enter a number')
          return false;
        }

        if (parseInt(document.getElementById('sessions').value) < 2000) {
          setSessionHelper('Minimum 2000 sessions. Please contact us for a custom plan')
          return false;
        }
        if (parseInt(document.getElementById('sessions').value) > 10000) {
          setSessionHelper('Maximum 10000 sessions. Please contact us for a custom plan')
          return false;
        }
      }
      return true;
  }

  const vc_price = () => {
    
      let sessions = parseFloat(document.getElementById('sessions').value)
      var table = document.getElementById('vc_table');
      console.log('Entered Sessions', sessions)
      if(sessions >= 2000 && sessions <= 10000 && sessionCheck())
      {
        for(let trow = (table.rows.length)-2;trow>0;trow--)
        {
          console.log("Row length",table.rows.length)
          console.log("Deleting Row",trow)
          table.deleteRow(trow);

        }
        let total
        total = 2000 * 0.46
        console.log('For 2000',total)
        document.getElementById('validity').value="3 months";
        let row = table.insertRow(1)
        row.className="MuiTableRow-root"
        let cell1 = row.insertCell(0)
        let cell2 = row.insertCell(1)
        let cell3 = row.insertCell(2)
        cell1.innerHTML="2000"
        cell1.align="right"
        cell1.className="MuiTableCell-root MuiTableCell-body"
        cell2.innerHTML="0.46"
        cell2.align="right"
        cell2.className="MuiTableCell-root MuiTableCell-body"
        cell3.innerHTML=total.toFixed(2)
        cell3.align="right"
        cell3.className="MuiTableCell-root MuiTableCell-body"
        if( sessions > 2000 )
        {
          let rem_sessions = sessions - 2000
          console.log('sessions-2000',rem_sessions)
          if(rem_sessions <= 3000)
          {
            total += rem_sessions * 0.4
            document.getElementById('validity').value="6 months";
            console.log('2000 to 5000',total)
            row = table.insertRow(2)
            row.className="MuiTableRow-root"
            cell1 = row.insertCell(0)
            cell2 = row.insertCell(1)
            cell3 = row.insertCell(2)
            cell1.innerHTML=rem_sessions
            cell1.align="right"
            cell1.className="MuiTableCell-root MuiTableCell-body"
            cell2.innerHTML="0.40"
            cell2.align="right"
            cell2.className="MuiTableCell-root MuiTableCell-body"
            cell3.innerHTML=(rem_sessions * 0.4).toFixed(2)
            cell3.align="right"
            cell3.className="MuiTableCell-root MuiTableCell-body"
          }
          else if(rem_sessions > 3000)
          {
            total += (3000 * 0.4) + ( (rem_sessions-3000) * 0.34)
            document.getElementById('validity').value="9 months";
            console.log('5000 plus',total)
            row = table.insertRow(2)
            row.className="MuiTableRow-root"
            cell1 = row.insertCell(0)
            cell2 = row.insertCell(1)
            cell3 = row.insertCell(2)
            cell1.innerHTML="3000"
            cell1.align="right"
            cell1.className="MuiTableCell-root MuiTableCell-body"
            cell2.innerHTML="0.40"
            cell2.align="right"
            cell2.className="MuiTableCell-root MuiTableCell-body"
            cell3.innerHTML=(3000 * 0.4).toFixed(2)
            cell3.align="right"
            cell3.className="MuiTableCell-root MuiTableCell-body"
            row = table.insertRow(3)
            row.className="MuiTableRow-root"
            cell1 = row.insertCell(0)
            cell2 = row.insertCell(1)
            cell3 = row.insertCell(2)
            cell1.innerHTML=rem_sessions-3000
            cell1.align="right"
            cell1.className="MuiTableCell-root MuiTableCell-body"
            cell2.innerHTML="0.34"
            cell2.align="right"
            cell2.className="MuiTableCell-root MuiTableCell-body"
            cell3.innerHTML=((rem_sessions-3000) * 0.34).toFixed(2)
            cell3.align="right"
            cell3.className="MuiTableCell-root MuiTableCell-body"
          }
        }
        total = total.toFixed(2)
        document.getElementById('vc_t_total'). innerHTML=total;
        console.log('vc total', total)
        return total;
      }
      return (sessions * 0.46).toFixed(2);
}

  const onAdd = () => {
    let vc_plan_price = 0.00;
    if(document.getElementById('add-on-check').checked && !isEmptyOrSpaces(document.getElementById('sessions').value))
    {
      vc_plan_price = vc_price()
    }
    else
    {
      vc_price()
    }
    console.log("vc", vc_plan_price);
    let x = parseFloat((parseFloat(document.getElementById("plan").options[document.getElementById("plan").selectedIndex].value) * parseInt(document.getElementById("students").value)) + parseFloat(vc_plan_price));
      console.log('rofl', x);
    x = parseFloat(x).toFixed(2);
    console.log('rofled', x);
    setTotal(x);
  };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  }

  const onChange = (event) => {
    let valid;
    console.log("lol", event);
    var shit = event.target.id;
    // return true;
    switch (event.target.id) {
      case "email":
        {
          // setData({ ...data, email: event.target.value });
          valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            event.target.value
          );

          if (!valid) {
            setEmailHelper("Please enter the correct email");
          } else {
            setEmailHelper("");
          }
        }
        break;
      case "mobile":
        {
          console.log("ROFL", event.target.value);
          // setData({ ...data, mobile: event.target.value });
          valid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
            event.target.value
          );
          if (!valid) {
            setMobileHelper("Please enter the correct mobile no");
          } else {
            setMobileHelper("");
          }
        }
        break;
      case "name":
        {
          // setData({ ...data, name: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setNameHelper("Please enter the name");
            //  setNameFocus(true)
            // name.focus(true);
          } else {
            setNameHelper("");
            //  setNameFocus(false)
            // name.focus(false);
          }
        }
        break;
      case "country":
        {
          // setData({ ...data, country: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          // setData({...data, country: value.label})

          if (event.target.value) {
            setCountryHelper("");
          } else {
            setCountryHelper("Please enter the country name");
          }
        }
        break;
      case "institution_name":
        {
          // setData({ ...data, institution_name: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setinstitution_nameHelper("Please enter the Instiitution name");
          } else {
            setinstitution_nameHelper("");
          }
        }
        break;
      case "role":
        {
          // setData({ ...data, role: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setRoleHelper("Please enter the role ");
          } else {
            setRoleHelper("");
          }
        }
        break;
      case "city":
        {
          // setData({ ...data, city: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setCityHelper("Please enter the city name ");
          } else {
            setCityHelper("");
          }
        }
        break;
      case "institution_website":
        {
          // setData({ ...data, institution_website: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setinstitution_websiteHelper("Please enter the Instiution website");
          } else {
            setinstitution_websiteHelper("");
          }
        }
        break;
      case "plan":
        {
          // setData({ ...data, price: event.target.value });
          //  setPrice(event.target.value)
          document.getElementById("price").value = event.target.value;
          onAdd();

          // valid=(event.target.value < 1).test(event.target.value)
          if (!event.target.value) {
            setPlanHelper("Please select a Plan");
          } else {
            setPlanHelper("");
          }
        }
        break;
      case "students":
        {
          if (parseInt(event.target.value) < 100) {
            document.getElementById("students").value = 100;
          }
          // setData({ ...data, students: event.target.value });
          onAdd();
          // valid=(event.target.value < 1).test(event.target.value)
          // setStudents(event.target.value)
          if (!event.target.value) {
            setStudentsHelper("Enter no of students");
          } else {
            setStudentsHelper("");
          }
          // let x = ( data.price * data.students )
          // console.log(x);
          // setTotal(x);
        }
        break;
      case "price":
        {
          // setData({ ...data, price: event.target.value });
          // valid=(event.target.value < 1).test(event.target.value)
          // setPrice(event.target.value)
          // if (!event.target.value) {
          //   setStudentsHelper("Enter ");
          // } else {
          //   setStudentsHelper("");
          // }
          onAdd();
          return;
        }
        break;
      case "sessions":{
        event.preventDefault();
        // if (event.target.value == "") {
        //   document.getElementById("sessions").value = 2000;
        // }
        setSessionHelper('')
        sessionCheck();
        onAdd();
      }
      break;
      default:
        break;
    }
  };

  const reset = () => {
    document.getElementById("name").value = "";
    document.getElementById("mobile").value = "";
    document.getElementById("mobile").value = "";
    document.getElementById("email").value = "";
    document.getElementById("country").value = "";
    document.getElementById("role").value = "";
    document.getElementById("institution_name").value = "";
    document.getElementById("institution_website").value = "";
    document.getElementById("city").value = "";
    document.getElementById("students").value = 100;
    document.getElementById("price").value = props.price;
    document.getElementById("plan").value = props.price;
    setTotal(0);
    setNameHelper("");
    setMobileHelper("");
    setCountryHelper("");
    setinstitution_nameHelper("");
    setinstitution_websiteHelper("");
    setRoleHelper("");
    setEmailHelper("");
    setCityHelper("");
    // setPlanHelper("");
    setStudentsHelper("");
  };

  const { width } = props;
  Register.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
  };
  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Registering you. Please wait..."
    >
      <Grid
        container
        //  direction="row"
        className={classes.back}
        alignItems="center"
        justify="center"
      >
        <Card
          className={classes.card}
          style={{ overflowY: screenMD ? "auto" : "hidden" }}
          // style={{overflowY:"auto",}}
        >
          <Grid container direction="row">
            <Hidden only={["sm", "xs", "md"]}>
              <Grid
                item
                container
                className={classes.background}
                alignItems="center"
                direction="column"
                // xl={5}
                lg={5}
                // md={4}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    // justify="center"
                    // alignItems="center"
                  >
                    {/* <Grid item className={classes.logo}>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{ width: "20em", alignItems: "center" }}
                    ></img>
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid
              item
              container
              direction="row"
              alignItems="flex-start"
              justify="flex-start"
              // xl={7}
              lg={7}
              xs
              // md={8}
            >
              {/* <Grid item>
          <Typography varient="h1">Register</Typography>
        </Grid> */}
              <CardContent>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    overflowY: "auto",
                    height: "90vh",
                    overflowX: "hidden",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={Logo4}
                        alt="logo"
                        style={{
                          width: "17em",
                          alignItems: "center",
                          // backgroundColor: "#244f50",
                          borderRadius: 10,
                        }}
                      ></img>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    // justify="space-evenly"
                    justify="center"
                    spacing={2}
                    style={{ marginTop: "1em" }}
                  >
                    {/* <Grid item>
                 <Typography varient="title">Register</Typography>
                 </Grid> */}
                    <Grid item>
                      <TextField
                        // size="small"
                        label="Name"
                        InputProps={{
                          classes: {
                            input: classes.resize,
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        id="name"
                        onChange={onChange}
                        // variant="filled"
                        variant="outlined"
                        className={classes.textField}
                        required
                        error={nameHelper.length !== 0}
                        helperText={nameHelper}
                      />
                    </Grid>
                    <Grid item>
                      {/* <TextField
                      required
                      
                      // style={{ width: 200 }}
                      InputLabelProps={{ style: { color: '#244f50',fontWeight: "bold" } }}
                      label="Mobile no"
                      id="mobile"
                      type="number"
                      value={data.mobile}
                      onChange={onChange}
                      variant="outlined"
                      className={classes.textField}
                      error={mobileHelper.length !== 0}
                      helperText={mobileHelper}
                      // size="small"
                   /  >  */}

                      <MuiPhoneNumber
                        // preferredCountries={["us"]}
                        InputProps={{ id: "mobile", required: true }}
                        defaultCountry={"in"}
                        enableSearch
                        disableSearchIcon
                        autoFormat={false}
                        variant="outlined"
                        type="phone"
                        id="mobile"
                        label="Mobile no"
                        className={classes.textField}
                        error={mobileHelper.length !== 0}
                        helperText={mobileHelper}
                        //  InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        inputExtraProps={{
                          margin: "normal",
                          autoComplete: "phone",
                          // name: 'custom-username',
                          label: "mobile",
                          variant: "outlined",
                          id: "mobile",
                        }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        onChange={(phone, info) => {
                          console.log(info);
                          setDialCode(info.dialCode);
                          setCountryCode(info.countryCode);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        id="country"
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        className={classes.textField}
                        options={countries}
                        classes={{
                          option: classes.option,
                        }}
                        freeSolo={false}
                        // size="small"
                        //  value={data.country}
                        //  onChange={onChange}
                        error={countryHelper.length !== 0}
                        helperText={countryHelper}
                        // onChange={(event, value) => { if (value) setData({ ...data, country: value.label }) }
                        // }
                        // inputValue={data.country}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label}({option.code})
                            {/* +{option.mobile} */}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            // size="small"
                            required
                            onChange={onChange}
                            // error={countryHelper.length !== 0}
                            // helperText={countryHelper}
                            {...params}
                            label="Choose a country"
                            InputLabelProps={{
                              classes: {
                                focused: classes.textFieldLabel,
                              },
                            }}
                            variant="outlined"
                            // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            InputLabelProps={{
                              classes: {
                                focused: classes.textFieldLabel,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="City"
                        required
                        id="city"
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        //  select
                        variant="outlined"
                        onChange={onChange}
                        fullWidth
                        error={cityHelper.length !== 0}
                        helperText={cityHelper}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        // size="small"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        // size="small"
                        className={classes.textField}
                        label="Institution Name"
                        id="institution_name"
                        onChange={onChange}
                        variant="outlined"
                        error={institution_nameHelper.length !== 0}
                        helperText={institution_nameHelper}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        // size="small"
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        variant="outlined"
                        label="Designation"
                        id="role"
                        onChange={onChange}
                        className={classes.textField}
                        error={roleHelper.length !== 0}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        helperText={roleHelper}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        // size="small"
                        label="Official Email"
                        id="email"
                        variant="outlined"
                        onChange={onChange}
                        className={classes.textField}
                        fullWidth
                        error={emailHelper.length !== 0}
                        helperText={emailHelper}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                      />
                    </Grid>

                    {/* <Grid item>
                    <TextField
                       required
                      InputLabelProps={{ style: { color: '#244f50',fontWeight: "bold" } }}
                      label="School Board"
                      id="board"
                      // size="small"
                      value={data.board}
                      className={classes.textField}
                      variant="outlined"
                      onChange={onChange}
                      error={boardHelper.length !== 0}
                      helperText={boardHelper}
                    />
                  </Grid> */}
                    <Grid item>
                      <TextField
                        required
                        // size="small"
                        label="Institution website"
                        id="institution_website"
                        variant="outlined"
                        onChange={onChange}
                        className={classes.textField}
                        error={institution_websiteHelper.length !== 0}
                        helperText={institution_websiteHelper}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        select
                        // size="small"
                        label="Plan"
                        id="plan"
                        // value={price_list}
                        variant="outlined"
                        onChange={onChange}
                        defaultValue={props.price}
                        className={classes.textField}
                        error={planHelper.length !== 0}
                        helperText={planHelper}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        onClick={onAdd}
                      >
                        {price_list.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        // select
                        // size="small"
                        label="Price per student"
                        id="price"
                        // value={price}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        defaultValue={props.price}
                        // onClick={onAdd}
                        variant="outlined"
                        onChange={onChange}
                        className={classes.textField}
                        error={planHelper.length !== 0}
                        helperText={planHelper}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        SelectProps={{
                          native: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      >
                        {/* {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.price}
                        </option>
                      ))} */}
                        {currencies.map((option) => (
                          <Typography>{option.price}</Typography>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        // size="small"

                        label="No of Students"
                        id="students"
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        //  #080A3C
                        //  value={100}
                        defaultValue={100}
                        variant="outlined"
                        onChange={onChange}
                        onKeyUp={onAdd}
                        className={classes.textField}
                        error={studentsHelper.length !== 0}
                        helperText={studentsHelper}
                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                        InputLabelProps={{
                          classes: {
                            focused: classes.textFieldLabel,
                          },
                        }}
                        InputProps={{ inputProps: { min: 100 } }}
                        type="number"
                      />
                    </Grid>

                    {/* <Grid item>



                    <Typography className={classes.total} id="total">Total amount: {total} </Typography>
                  </Grid> */}
                  </Grid>

                  <Grid
                    item
                    container
                    justify="center"
                    style={{ marginTop: "4vh" }}
                  >
                    <Grid item>
                      <Grid
                        item
                        container
                        justify="center"
                        // alignContent="flex-end"
                        style={{
                          border: "1px solid ",
                          //  width: "100%"
                        }}
                        className={classes.addOnWidth}
                      >
                        <Typography variant="h4" style={{
                                fontWeight: "Bold",
                                // fontSize: "1rem",
                                fontFamily: "'Poppins', sans-serif",
                              }}>Add-Ons</Typography>
                      </Grid>
                      <Grid item container 
                      
                       style={{ width: "100%" }}
                      >
                        <ExpansionPanel
                         style={{ width: "100%" }}
                         >
                          <ExpansionPanelSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "white" }} />
                              // <Checkbox style={{ color: "white" }} />
                            }
                            style={{
                              backgroundColor: "#01294f",
                              lineHeight: 0.75,
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontWeight: "Bold",
                                color: "white",
                                fontSize: "1rem",
                                fontFamily: "'Poppins', sans-serif",
                              }}
                            >
                            <Checkbox id={'add-on-check'} style={{ color: "white" }} size={'small'} onClick={e => {stopExpansion(e)}} onChange={(event) => { event.stopPropagation();onAdd();}}/>
                              Virtual Classroom
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid
                              item
                              container
                              justify="flex-start"
                              // alignItems="center"
                              // alignContent="center"
                              // spacing={8}
                            >
                              <Grid container direction="row">
                                <Card
                                  variant="outlined"
                                  style={{ width: "-webkit-fill-available" }}
                                >
                                  <CardContent>
                                    <Grid
                                      item
                                      container
                                      alignItems="center"
                                      justify="space-evenly"
                                    >
                                      <TextField
                                        label="No of Sessions"
                                        //  required
                                        id="sessions"
                                        className={classes.addOnField}
                                        InputLabelProps={{
                                          classes: {
                                            focused: classes.textFieldLabel,
                                          },
                                        }}
                                        //  select
                                        variant="outlined"
                                        // onfocusout={alert('Blue')}
                                        // onBlur={sessionCheck}
                                         onChange={onChange}
                                        //  onKeyUp={onChange}
                                        //  onKeyPress={onChange}
                                        InputProps={{
                                          // inputProps: { min: 2000 , max: 10000},
                                        }}
                                        type="number"
                                        error={sessionHelper.length !== 0}
                                        helperText={sessionHelper}
                                        defaultValue={2000}
                                      />
                                      <TextField
                                        required
                                        // select
                                        // size="small"
                                        label="Validity"
                                        id="validity"
                                        // value={'3 months'}
                                        InputLabelProps={{
                                          classes: {
                                            focused: classes.textFieldLabel,
                                          },
                                        }}
                                        defaultValue={'3 months'}
                                        // onClick={onAdd}
                                        variant="outlined"
                                        className={classes.addOnField}
                                        // onChange={onChange}

                                        // error={planHelper.length !== 0}
                                        // helperText={planHelper}
                                        // InputLabelProps={{ style: { color: '#244f50', fontWeight: "bold" } }}
                                        InputLabelProps={{
                                          classes: {
                                            focused: classes.textFieldLabel,
                                          },
                                        }}
                                        // SelectProps={{
                                        //   native: true,
                                        // }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </Grid>
                                    {/* <Grid
                                      item
                                      container
                                      // alignItems="center"
                                      direction="column"
                                      // className={classes.addOnWidth}
                                      
                                      //  justify="flex-end"
                                      //  alignItems="flex-start"
                                      //  alignContent="flex-end"
                                    > */}
                                      {/* <Typography>Price per session:$0.46</Typography>
                               
                                 <Typography>Validity: 3 months</Typography> */}

                                      {/* https://codesandbox.io/s/wpzrl?file=/demo.js */}
                                      {/* for ref for table see above link */}
                                      <TableContainer >
                                        <Table id={'vc_table'}  >
                                          <TableHead>
                                            <TableRow>
                                            <TableCell
                                                style={{ fontWeight: "bold" }}
                                                align="right"
                                              >
                                                {" "}
                                                No of sessions
                                              </TableCell>
                                              <TableCell
                                                style={{ fontWeight: "bold" }}
                                                align="right"
                                              >
                                                {" "}
                                                Price per Session($)
                                              </TableCell>
                                              
                                             
                                              <TableCell
                                                style={{ fontWeight: "bold" }}
                                                align="right"
                                              >
                                                {" "}
                                                Total($){" "}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                           <TableRow>                                 
                                           <TableCell align="right">
                                              2000
                                            </TableCell>
                                            <TableCell align="right"> 0.46</TableCell>
                                            <TableCell align="right">
                                              920
                                            </TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell style={{ fontWeight: "bold" }} 
                                            // colSpan={2}
                                            >Total($)</TableCell>
                                            <TableCell style={{ fontWeight: "bold" }} align="right"></TableCell>
                                            <TableCell align="right" id="vc_t_total">920</TableCell>
                                            </TableRow>
   
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    {/* </Grid> */}
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    </Grid>
                  </Grid>

                  <CardActions>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justify="center"
                      spacing={4}
                      style={{ marginTop: "0.5em" }}
                    >
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="center"
                        spacing={4}

                        // style={{marginTop:"0.5em"}}
                      >
                        <Grid
                          item
                          //  xs={12}  sm={6 }

                          // justify="center"
                        >
                          {/* <Link  to="/payment"> */}
                          <Button
                            className={classes.createButton}
                            varient="contained"
                            disableRipple
                            type="submit"
                            onSubmit={handleSubmit}
                            //  component={Link}
                            // onClick={event =>  window.location.href='/payment'}
                            // onClick={() => history.push("/Payment")}
                          >
                            Register (${total})
                          </Button>
                          {/* </Link> */}
                        </Grid>
                        <Grid
                          item
                          //  xs={12} sm={6}

                          // container
                        >
                          <Button
                            varient="contained"
                            className={classes.resetButton}
                            // type="reset"
                            disableRipple
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardActions>
                </form>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </LoadingOverlay>
  );
}
function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
const countries = [
  { code: "AD", label: "Andorra", phone: "+376" },
  { code: "AE", label: "United Arab Emirates", phone: "+971" },
  { code: "AF", label: "Afghanistan", phone: "+93" },
  { code: "AG", label: "Antigua and Barbuda", phone: "+1-268" },
  { code: "AI", label: "Anguilla", phone: "+1-264" },
  { code: "AL", label: "Albania", phone: "+355" },
  { code: "AM", label: "Armenia", phone: "+374" },
  { code: "AO", label: "Angola", phone: "+244" },
  { code: "AQ", label: "Antarctica", phone: "+672" },
  { code: "AR", label: "Argentina", phone: "+54" },
  { code: "AS", label: "American Samoa", phone: "+1-684" },
  { code: "AT", label: "Austria", phone: "+43" },
  { code: "AU", label: "Australia", phone: "+61", suggested: true },
  { code: "AW", label: "Aruba", phone: "+297" },
  { code: "AX", label: "Alland Islands", phone: "+358" },
  { code: "AZ", label: "Azerbaijan", phone: "+994" },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "+387" },
  { code: "BB", label: "Barbados", phone: "+1-246" },
  { code: "BD", label: "Bangladesh", phone: "+880" },
  { code: "BE", label: "Belgium", phone: "+32" },
  { code: "BF", label: "Burkina Faso", phone: "+226" },
  { code: "BG", label: "Bulgaria", phone: "+359" },
  { code: "BH", label: "Bahrain", phone: "+973" },
  { code: "BI", label: "Burundi", phone: "+257" },
  { code: "BJ", label: "Benin", phone: "+229" },
  { code: "BL", label: "Saint Barthelemy", phone: "+590" },
  { code: "BM", label: "Bermuda", phone: "+1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "+673" },
  { code: "BO", label: "Bolivia", phone: "+591" },
  { code: "BR", label: "Brazil", phone: "+55" },
  { code: "BS", label: "Bahamas", phone: "+1-242" },
  { code: "BT", label: "Bhutan", phone: "+975" },
  { code: "BV", label: "Bouvet Island", phone: "+47" },
  { code: "BW", label: "Botswana", phone: "+267" },
  { code: "BY", label: "Belarus", phone: "+375" },
  { code: "BZ", label: "Belize", phone: "+501" },
  { code: "CA", label: "Canada", phone: "+1", suggested: true },
  { code: "CC", label: "Cocos (Keeling) Islands", phone: "+61" },
  { code: "CD", label: "Congo, Democratic Republic of the", phone: "+243" },
  { code: "CF", label: "Central African Republic", phone: "+236" },
  { code: "CG", label: "Congo, Republic of the", phone: "+242" },
  { code: "CH", label: "Switzerland", phone: "+41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "+225" },
  { code: "CK", label: "Cook Islands", phone: "+682" },
  { code: "CL", label: "Chile", phone: "+56" },
  { code: "CM", label: "Cameroon", phone: "+237" },
  { code: "CN", label: "China", phone: "+86" },
  { code: "CO", label: "Colombia", phone: "+57" },
  { code: "CR", label: "Costa Rica", phone: "+506" },
  { code: "CU", label: "Cuba", phone: "+53" },
  { code: "CV", label: "Cape Verde", phone: "+238" },
  { code: "CW", label: "Curacao", phone: "+599" },
  { code: "CX", label: "Christmas Island", phone: "+61" },
  { code: "CY", label: "Cyprus", phone: "+357" },
  { code: "CZ", label: "Czech Republic", phone: "+420" },
  { code: "DE", label: "Germany", phone: "+49", suggested: true },
  { code: "DJ", label: "Djibouti", phone: "+253" },
  { code: "DK", label: "Denmark", phone: "+45" },
  { code: "DM", label: "Dominica", phone: "+1-767" },
  { code: "DO", label: "Dominican Republic", phone: "+1-809" },
  { code: "DZ", label: "Algeria", phone: "+213" },
  { code: "EC", label: "Ecuador", phone: "+593" },
  { code: "EE", label: "Estonia", phone: "+372" },
  { code: "EG", label: "Egypt", phone: "+20" },
  { code: "EH", label: "Western Sahara", phone: "+212" },
  { code: "ER", label: "Eritrea", phone: "+291" },
  { code: "ES", label: "Spain", phone: "+34" },
  { code: "ET", label: "Ethiopia", phone: "+251" },
  { code: "FI", label: "Finland", phone: "+358" },
  { code: "FJ", label: "Fiji", phone: "+679" },
  { code: "FK", label: "Falkland Islands (Malvinas)", phone: "+500" },
  { code: "FM", label: "Micronesia, Federated States of", phone: "+691" },
  { code: "FO", label: "Faroe Islands", phone: "+298" },
  { code: "FR", label: "France", phone: "+33", suggested: true },
  { code: "GA", label: "Gabon", phone: "+241" },
  { code: "GB", label: "United Kingdom", phone: "+44" },
  { code: "GD", label: "Grenada", phone: "+1-473" },
  { code: "GE", label: "Georgia", phone: "+995" },
  { code: "GF", label: "French Guiana", phone: "+594" },
  { code: "GG", label: "Guernsey", phone: "+44" },
  { code: "GH", label: "Ghana", phone: "+233" },
  { code: "GI", label: "Gibraltar", phone: "+350" },
  { code: "GL", label: "Greenland", phone: "+299" },
  { code: "GM", label: "Gambia", phone: "+220" },
  { code: "GN", label: "Guinea", phone: "+224" },
  { code: "GP", label: "Guadeloupe", phone: "+590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "+240" },
  { code: "GR", label: "Greece", phone: "+30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "+500",
  },
  { code: "GT", label: "Guatemala", phone: "+502" },
  { code: "GU", label: "Guam", phone: "+1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "+245" },
  { code: "GY", label: "Guyana", phone: "+592" },
  { code: "HK", label: "Hong Kong", phone: "+852" },
  { code: "HM", label: "Heard Island and McDonald Islands", phone: "+672" },
  { code: "HN", label: "Honduras", phone: "+504" },
  { code: "HR", label: "Croatia", phone: "+385" },
  { code: "HT", label: "Haiti", phone: "+509" },
  { code: "HU", label: "Hungary", phone: "+36" },
  { code: "ID", label: "Indonesia", phone: "+62" },
  { code: "IE", label: "Ireland", phone: "+353" },
  { code: "IL", label: "Israel", phone: "+972" },
  { code: "IM", label: "Isle of Man", phone: "+44" },
  { code: "IN", label: "India", phone: "+91" },
  { code: "IO", label: "British Indian Ocean Territory", phone: "+246" },
  { code: "IQ", label: "Iraq", phone: "+964" },
  { code: "IR", label: "Iran, Islamic Republic of", phone: "+98" },
  { code: "IS", label: "Iceland", phone: "+354" },
  { code: "IT", label: "Italy", phone: "+39" },
  { code: "JE", label: "Jersey", phone: "+44" },
  { code: "JM", label: "Jamaica", phone: "+1-876" },
  { code: "JO", label: "Jordan", phone: "+962" },
  { code: "JP", label: "Japan", phone: "+81", suggested: true },
  { code: "KE", label: "Kenya", phone: "+254" },
  { code: "KG", label: "Kyrgyzstan", phone: "+996" },
  { code: "KH", label: "Cambodia", phone: "+855" },
  { code: "KI", label: "Kiribati", phone: "+686" },
  { code: "KM", label: "Comoros", phone: "+269" },
  { code: "KN", label: "Saint Kitts and Nevis", phone: "+1-869" },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "+850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "+82" },
  { code: "KW", label: "Kuwait", phone: "+965" },
  { code: "KY", label: "Cayman Islands", phone: "+1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "+7" },
  { code: "LA", label: "Lao People's Democratic Republic", phone: "+856" },
  { code: "LB", label: "Lebanon", phone: "+961" },
  { code: "LC", label: "Saint Lucia", phone: "+1-758" },
  { code: "LI", label: "Liechtenstein", phone: "+423" },
  { code: "LK", label: "Sri Lanka", phone: "+94" },
  { code: "LR", label: "Liberia", phone: "+231" },
  { code: "LS", label: "Lesotho", phone: "+266" },
  { code: "LT", label: "Lithuania", phone: "+370" },
  { code: "LU", label: "Luxembourg", phone: "+352" },
  { code: "LV", label: "Latvia", phone: "+371" },
  { code: "LY", label: "Libya", phone: "+218" },
  { code: "MA", label: "Morocco", phone: "+212" },
  { code: "MC", label: "Monaco", phone: "+377" },
  { code: "MD", label: "Moldova, Republic of", phone: "+373" },
  { code: "ME", label: "Montenegro", phone: "+382" },
  { code: "MF", label: "Saint Martin (French part)", phone: "+590" },
  { code: "MG", label: "Madagascar", phone: "+261" },
  { code: "MH", label: "Marshall Islands", phone: "+692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "+389",
  },
  { code: "ML", label: "Mali", phone: "+223" },
  { code: "MM", label: "Myanmar", phone: "+95" },
  { code: "MN", label: "Mongolia", phone: "+976" },
  { code: "MO", label: "Macao", phone: "+853" },
  { code: "MP", label: "Northern Mariana Islands", phone: "+1-670" },
  { code: "MQ", label: "Martinique", phone: "+596" },
  { code: "MR", label: "Mauritania", phone: "+222" },
  { code: "MS", label: "Montserrat", phone: "+1-664" },
  { code: "MT", label: "Malta", phone: "+356" },
  { code: "MU", label: "Mauritius", phone: "+230" },
  { code: "MV", label: "Maldives", phone: "+960" },
  { code: "MW", label: "Malawi", phone: "+265" },
  { code: "MX", label: "Mexico", phone: "+52" },
  { code: "MY", label: "Malaysia", phone: "+60" },
  { code: "MZ", label: "Mozambique", phone: "+258" },
  { code: "NA", label: "Namibia", phone: "+264" },
  { code: "NC", label: "New Caledonia", phone: "+687" },
  { code: "NE", label: "Niger", phone: "+227" },
  { code: "NF", label: "Norfolk Island", phone: "+672" },
  { code: "NG", label: "Nigeria", phone: "+234" },
  { code: "NI", label: "Nicaragua", phone: "+505" },
  { code: "NL", label: "Netherlands", phone: "+31" },
  { code: "NO", label: "Norway", phone: "+47" },
  { code: "NP", label: "Nepal", phone: "+977" },
  { code: "NR", label: "Nauru", phone: "+674" },
  { code: "NU", label: "Niue", phone: "+683" },
  { code: "NZ", label: "New Zealand", phone: "+64" },
  { code: "OM", label: "Oman", phone: "+968" },
  { code: "PA", label: "Panama", phone: "+507" },
  { code: "PE", label: "Peru", phone: "+51" },
  { code: "PF", label: "French Polynesia", phone: "+689" },
  { code: "PG", label: "Papua New Guinea", phone: "+675" },
  { code: "PH", label: "Philippines", phone: "+63" },
  { code: "PK", label: "Pakistan", phone: "+92" },
  { code: "PL", label: "Poland", phone: "+48" },
  { code: "PM", label: "Saint Pierre and Miquelon", phone: "+508" },
  { code: "PN", label: "Pitcairn", phone: "+870" },
  { code: "PR", label: "Puerto Rico", phone: "+1" },
  { code: "PS", label: "Palestine, State of", phone: "+970" },
  { code: "PT", label: "Portugal", phone: "+351" },
  { code: "PW", label: "Palau", phone: "+680" },
  { code: "PY", label: "Paraguay", phone: "+595" },
  { code: "QA", label: "Qatar", phone: "+974" },
  { code: "RE", label: "Reunion", phone: "+262" },
  { code: "RO", label: "Romania", phone: "+40" },
  { code: "RS", label: "Serbia", phone: "+381" },
  { code: "RU", label: "Russian Federation", phone: "+7" },
  { code: "RW", label: "Rwanda", phone: "+250" },
  { code: "SA", label: "Saudi Arabia", phone: "+966" },
  { code: "SB", label: "Solomon Islands", phone: "+677" },
  { code: "SC", label: "Seychelles", phone: "+248" },
  { code: "SD", label: "Sudan", phone: "+249" },
  { code: "SE", label: "Sweden", phone: "+46" },
  { code: "SG", label: "Singapore", phone: "+65" },
  { code: "SH", label: "Saint Helena", phone: "+290" },
  { code: "SI", label: "Slovenia", phone: "+386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "+47" },
  { code: "SK", label: "Slovakia", phone: "+421" },
  { code: "SL", label: "Sierra Leone", phone: "+232" },
  { code: "SM", label: "San Marino", phone: "+378" },
  { code: "SN", label: "Senegal", phone: "+221" },
  { code: "SO", label: "Somalia", phone: "+252" },
  { code: "SR", label: "Suriname", phone: "+597" },
  { code: "SS", label: "South Sudan", phone: "+211" },
  { code: "ST", label: "Sao Tome and Principe", phone: "+239" },
  { code: "SV", label: "El Salvador", phone: "+503" },
  { code: "SX", label: "Sint Maarten (Dutch part)", phone: "+1-721" },
  { code: "SY", label: "Syrian Arab Republic", phone: "+963" },
  { code: "SZ", label: "Swaziland", phone: "+268" },
  { code: "TC", label: "Turks and Caicos Islands", phone: "+1-649" },
  { code: "TD", label: "Chad", phone: "+235" },
  { code: "TF", label: "French Southern Territories", phone: "+262" },
  { code: "TG", label: "Togo", phone: "+228" },
  { code: "TH", label: "Thailand", phone: "+66" },
  { code: "TJ", label: "Tajikistan", phone: "+992" },
  { code: "TK", label: "Tokelau", phone: "+690" },
  { code: "TL", label: "Timor-Leste", phone: "+670" },
  { code: "TM", label: "Turkmenistan", phone: "+993" },
  { code: "TN", label: "Tunisia", phone: "+216" },
  { code: "TO", label: "Tonga", phone: "+676" },
  { code: "TR", label: "Turkey", phone: "+90" },
  { code: "TT", label: "Trinidad and Tobago", phone: "+1-868" },
  { code: "TV", label: "Tuvalu", phone: "+688" },
  { code: "TW", label: "Taiwan, Province of China", phone: "+886" },
  { code: "TZ", label: "United Republic of Tanzania", phone: "+255" },
  { code: "UA", label: "Ukraine", phone: "+380" },
  { code: "UG", label: "Uganda", phone: "+256" },
  { code: "US", label: "United States", phone: "+1", suggested: true },
  { code: "UY", label: "Uruguay", phone: "+598" },
  { code: "UZ", label: "Uzbekistan", phone: "+998" },
  { code: "VA", label: "Holy See (Vatican City State)", phone: "+379" },
  { code: "VC", label: "Saint Vincent and the Grenadines", phone: "+1-784" },
  { code: "VE", label: "Venezuela", phone: "+58" },
  { code: "VG", label: "British Virgin Islands", phone: "+1-284" },
  { code: "VI", label: "US Virgin Islands", phone: "+1-340" },
  { code: "VN", label: "Vietnam", phone: "+84" },
  { code: "VU", label: "Vanuatu", phone: "+678" },
  { code: "WF", label: "Wallis and Futuna", phone: "+681" },
  { code: "WS", label: "Samoa", phone: "+685" },
  { code: "XK", label: "Kosovo", phone: "+383" },
  { code: "YE", label: "Yemen", phone: "+967" },
  { code: "YT", label: "Mayotte", phone: "+262" },
  { code: "ZA", label: "South Africa", phone: "+27" },
  { code: "ZM", label: "Zambia", phone: "+260" },
  { code: "ZW", label: "Zimbabwe", phone: "+263" },
];
export default withWidth()(Register);
